var callendarElement = document.querySelectorAll('.input__calendar');
function dataInput(element) {
    flatpickr(element, {
        disableMobile: true,
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
            weekdays: {
              shorthand: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
              longhand: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],         
            }, 
            months: {
              shorthand: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
              longhand: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            },
        },
        onOpen: function(selectedDates, dateStr, instance) {
            instance.altInput.offsetParent.classList.add('input_focused');
        },
        onClose: function(selectedDates, dateStr, instance){
            if(dateStr === '') {
                instance.altInput.offsetParent.classList.remove('input_focused');
            }
        }
    });
}
dataInput('#secondStepPassportDateIssue');
dataInput('#secondStepDateOfBirthday');

callendarElement.forEach(function(item) {
    item.addEventListener('click', function() {
        var elementID = this.closest('.input').children[0].id,
            calendar = flatpickr(`#${elementID}`, {
                disableMobile: true,
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                locale: {
                    firstDayOfWeek: 1,
                    weekdays: {
                      shorthand: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                      longhand: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],         
                    }, 
                    months: {
                      shorthand: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                      longhand: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    },
                },
                onOpen: function(selectedDates, dateStr, instance) {
                    instance.altInput.offsetParent.classList.add('input_focused');
                },
                onClose: function(selectedDates, dateStr, instance){
                    if(dateStr === '') {
                        instance.altInput.offsetParent.classList.remove('input_focused');
                    }
                }
            });
        calendar.toggle();
    })
})