var mobileMenuBtn = document.querySelector('.header__menu'),
    mobileMenuClose = document.querySelector('.mobile-nav__control-close'),
    mobileMenu = document.querySelector('.mobile-nav');
mobileMenuBtn.addEventListener('click', function() {
    mobileMenu.classList.add('mobile-nav_showed');
})
mobileMenuClose.addEventListener('click', function() {
    mobileMenu.classList.remove('mobile-nav_showed');
})
document.addEventListener('click', function(e) {
    if(mobileMenu.classList.contains('mobile-nav_showed') && e.target == mobileMenu) {
        mobileMenu.classList.remove('mobile-nav_showed');
    }
});