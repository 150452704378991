var inputSelect = document.querySelectorAll('.input_select'),
    selectContent = document.querySelectorAll('.input__content');

function closeAllDropdown(elements) {
    elements.forEach(function(contents) {
        contents.classList.remove('open');
    });
}

inputSelect.forEach(function(item) {
    var inputSelectOptions = item.querySelectorAll('.input__content_option'),
        inputSelectLabel = item.querySelector('.input__label');
    item.addEventListener('click', function(e) {
        e.stopPropagation();
        var contentList = this.querySelector('.input__content'),
            inputValueLength = this.querySelectorAll('.input__elem')[0].value.length;
        if(contentList.classList.contains('open')) {
            contentList.classList.remove('open');
            if(inputValueLength <= 0) {
                this.classList.remove('input_focused');
            }
        } else {
            closeAllDropdown(selectContent);
            contentList.classList.add('open');
            this.classList.add('input_focused');
        }
    });
    inputSelectLabel.addEventListener('click', function(e) {
        e.stopPropagation();
    });
    document.addEventListener('click', function(event) {
        var isClickInside = item.contains(event.target);
        if (!isClickInside) {
            selectContent.forEach(function(contents) {
                contents.classList.remove('open');
            })
        }
    });
    inputSelectOptions.forEach(function(option) {
        option.addEventListener('click', function(e) {
            e.stopPropagation();
            var optionValue,
                inputElement = option.closest('.input_select').querySelector('.input__elem'),
                currentList = this.closest('.input__content_inner').querySelectorAll('.input__content_option');
            optionValue = this.textContent;
            inputElement.value = optionValue;
            currentList.forEach(function(listItem) {
                listItem.classList.remove('option_selected');
            });
            this.classList.add('option_selected');
            option.closest('.input__content').classList.remove('open');
            inputElement.closest('.input').classList.add('input_focused');
        })
    });
});