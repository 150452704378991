var inputElements = document.querySelectorAll('.input__elem');

inputElements.forEach(function(item) {
    item.addEventListener('focus', function() {
        this.closest('.input').classList.add('input_focused');
    });
    item.addEventListener('blur', function() {
        if(!(item.value)) {
            this.closest('.input').classList.remove('input_focused');
        }
    });
});